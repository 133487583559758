import {
  ActionBlock,
  AddListButton,
  Container,
  ContentItem,
  DescriptionItem,
  HeadBlock,
  ImgStyled,
  IncTitle,
  Info,
  InstallButton,
  Item,
  ListBlock,
  ListIcon,
  MainTitle,
  SubDescription,
  SubTitle,
} from './MainInfo.styled'
import iconSrc from './assets/logo.jpg'
import other18Src from './assets/other_18.png'
import { ClockIcon, AddListIcon, StarIcon } from './assets'

export const MainInfo: React.FC<{
  deferredPrompt?: any
  handleInstall: () => void
  onOpenInChrome: () => void
  isLoading: boolean
  isInAppBrowser: boolean
}> = ({ handleInstall, onOpenInChrome, isLoading, isInAppBrowser }) => {
  const textInstall = isLoading ? 'Initializing...' : 'Install'

  return (
    <Container>
      <HeadBlock>
        <ImgStyled src={iconSrc} alt={'Icon'} />
        <Info>
          <MainTitle>Aviator TopX</MainTitle>
          <SubTitle>TopX Soft</SubTitle>
          <IncTitle>Contains ads · In-app purchases</IncTitle>
        </Info>
      </HeadBlock>
      <ListBlock>
        <Item>
          <ContentItem>
            4.9
            <StarIcon />
          </ContentItem>
          <DescriptionItem>8.5K reviews</DescriptionItem>
        </Item>
        <Item>
          <ContentItem>100K+</ContentItem>
          <DescriptionItem>Downloads</DescriptionItem>
        </Item>
        <Item>
          <ContentItem>
            <ClockIcon />
          </ContentItem>
          <DescriptionItem>Editors' Choice</DescriptionItem>
        </Item>
        <Item>
          <ContentItem>
            <ListIcon src={other18Src} alt={'other 18'} />
          </ContentItem>
          <DescriptionItem>Rated for 18+</DescriptionItem>
        </Item>
      </ListBlock>
      <ActionBlock>
        <InstallButton onClick={isInAppBrowser ? onOpenInChrome : handleInstall}>
          {isInAppBrowser ? 'Open to browser' : textInstall}
        </InstallButton>
        <AddListButton>
          <AddListIcon /> Add to wishlist
        </AddListButton>
      </ActionBlock>
      <SubDescription>This app is available for all of your devices.</SubDescription>
    </Container>
  )
}
