import img1 from '@pages/Install/components/Comments/assets/1.png'
import img2 from '@pages/Install/components/Comments/assets/2.png'
import img3 from '@pages/Install/components/Comments/assets/3.png'
import img4 from '@pages/Install/components/Comments/assets/4.png'
import img5 from '@pages/Install/components/Comments/assets/5.png'
import img6 from '@pages/Install/components/Comments/assets/6.png'
import img7 from '@pages/Install/components/Comments/assets/7.png'
import img8 from '@pages/Install/components/Comments/assets/8.png'
import img9 from '@pages/Install/components/Comments/assets/9.png'
import img10 from '@pages/Install/components/Comments/assets/10.png'
import img11 from '@pages/Install/components/Comments/assets/11.png'
import img12 from '@pages/Install/components/Comments/assets/12.png'
import img13 from '@pages/Install/components/Comments/assets/13.png'
import img14 from '@pages/Install/components/Comments/assets/14.png'
import img15 from '@pages/Install/components/Comments/assets/15.png'
import img16 from '@pages/Install/components/Comments/assets/16.png'
import img17 from '@pages/Install/components/Comments/assets/17.png'
import img18 from '@pages/Install/components/Comments/assets/18.png'
import img19 from '@pages/Install/components/Comments/assets/19.png'
import img20 from '@pages/Install/components/Comments/assets/20.png'
import img21 from '@pages/Install/components/Comments/assets/21.png'
import img22 from '@pages/Install/components/Comments/assets/22.png'
import img23 from '@pages/Install/components/Comments/assets/23.png'

export const listComments = [
  {
    icon: img1,
    name: 'धर्मात्मा',
    countStarts: 5,
    date: 'April 8, 2024',
    comment:
      "Very good application, I did not even think that you can win money on Aviator, I advise everyone to install the Aviator application, place bets and win money, because as they say, there is never too much money Have a chance to win with pleasure, and it's great, good luck to everyone who joins Aviator, good luck placing bets in this wonderful application.",
    peopleFound: 2,
  },
  {
    icon: img2,
    name: 'एलीटशिन',
    countStarts: 16,
    date: 'April 1, 2024',
    comment:
      'Very nice application. I never thought that I could get a lot of money. I recommend everyone to download this application',
    peopleFound: 16,
  },
  {
    icon: img3,
    name: 'रुनवॉल',
    countStarts: 5,
    date: 'March 29, 2024',
    comment:
      'The app is great!!! At first I did not think that I could win so much in the casino, but now I earn 1000 rubles a day, and sometimes 5 thousand rubles!!!! I recommend Aviator to everyone, of course, if you want to make money. In my opinion this is the best sports betting app. From my review, everyone will decide whether to download 1xbet or not, but personally I recommend it!!!!',
    peopleFound: 54,
  },
  {
    icon: img4,
    name: 'Kamal',
    countStarts: 4,
    date: 'March 25, 2024',
    comment: 'Aviator is a good application where you can make good money on bets and casinos.',
    peopleFound: 1,
  },
  {
    icon: img5,
    name: 'Sundar',
    countStarts: 5,
    date: 'March 20, 2024',
    comment:
      "Aviator app is really good. I didn't think it was that easy. You install it and it works immediately. Overall very good. So I thought it was another scam. But I was sure that was not the case.",
    peopleFound: 22,
  },
  {
    icon: img6,
    name: 'Guddi',
    countStarts: 5,
    date: 'March 9, 2024',
    comment: 'Aviator is a great app that I recommend to everyone.',
    peopleFound: 6,
  },
  {
    icon: img7,
    name: 'Shazzad Hossain',
    countStarts: 5,
    date: 'March 1, 2024',
    comment:
      'मैंने एक ऑनलाइन कैसीनो में खेलने के लिए बहुत पैसा जीता और धन निकालने की सुविधा से प्रभावित हुआ ।  मैं मिनट के एक मामले में पैसे प्राप्त',
    peopleFound: 0,
  },
  {
    icon: img8,
    name: 'शेफ़816',
    countStarts: 5,
    date: 'February 26, 2024',
    comment:
      'Hello friends, I want to share with you my impression about Aviator application!!! Although I am not a gambling person, I was impressed by this application, you can bet safely and win, and withdrawing funds is very convenient. Bright and clear graphics! In general, good luck to all beginners and enthusiastic players',
    peopleFound: 34,
  },
  {
    icon: img9,
    name: 'पालपुर की जनता',
    countStarts: 5,
    date: 'February 24, 2024',
    comment:
      'Aviator is a great and easy earning company. I downloaded the application and I do not regret it. I play with pleasure and withdraw the money I earn to a bank account or even e-wallet. I advise you to try playing, you will not regret it. I have already recommended it to all my friends and acquaintances',
    peopleFound: 9,
  },
  {
    icon: img10,
    name: 'नरकंकाल',
    countStarts: 5,
    date: 'February 22, 2024',
    comment:
      'A very good aviator app. I like it very much. I recommend everyone to download this wonderful, amazing application, you can do a lot and it works very well. I highly recommend downloading this application, it is very well done. Thanks to the developers for such a wonderful application',
    peopleFound: 1,
  },
  {
    icon: img11,
    name: 'भाप से चलने वाला सोना',
    countStarts: 4,
    date: 'February 18, 2024',
    comment:
      "Aviator is the top best app for betting, it is absolutely amazing and starts from ₹20 to 12,000₹. Simply and easily. I used to play such bets and always lose, but in Aviator everything is fair, I never lose money and withdraw money with any payment method. Apart from this, there are many other wallets in which you can transfer money. If you don't believe me, check it out yourself!",
    peopleFound: 22,
  },
  {
    icon: img12,
    name: 'कल्पना',
    countStarts: 4,
    date: 'February 1, 2024',
    comment:
      'Aviator, great application for everyone and it has no problems and everything else is fine. Simple, intuitive application. Even a newbie can understand it, so you can download, install and start playing. In this direction, you can not only relax but also earn money by playing this game. Download and install.',
    peopleFound: 43,
  },
  {
    icon: img13,
    name: 'फर्डिनेंड',
    countStarts: 5,
    date: 'January 30, 2024',
    comment:
      'Aviator is an excellent application, clear interface, excellent graphics, no lag. Even a novice can figure it out. But the main advantage is that you can also earn extra money here! This is absolutely great! I have been using this application for a long time, it has never let me down, the output to the card is very convenient! In general, I recommend it to everyone.',
    peopleFound: 14,
  },
  {
    icon: img14,
    name: 'डिक्सी',
    countStarts: 4,
    date: 'January 28, 2024',
    comment:
      "A very excellent company Aviator, big wins, fast payouts and high possibilities, many sports games, very convenient and easy to use, even a newbie can figure it out in a few clicks and you are already earning money. So far, I've only heard mediocre comments about this app. I recommend everyone to try",
    peopleFound: 67,
  },
  {
    icon: img15,
    name: 'अज्ञात',
    countStarts: 5,
    date: 'January 20, 2024',
    comment:
      'Aviator Sports Betting is probably my favorite among the other casinos I use. For me personally, what matters is high-quality graphics and a user-friendly interface, combined with a real opportunity to place bets and try your luck. There are promotional codes and welcome bonuses for beginners. I recommend it to those who want to try themselves in this field. My rating is 5 stars. ,',
    peopleFound: 0,
  },
  {
    icon: img16,
    name: 'Nosleep2day',
    countStarts: 5,
    date: 'January 19, 2024',
    comment: 'Super casino office aviator. i really like this app',
    peopleFound: 12,
  },
  {
    icon: img17,
    name: 'केवीएनएन',
    countStarts: 5,
    date: 'January 28, 2024',
    comment:
      'Hello everyone, I want to tell you about the excellent Aviator Casino application. The interface is cosmic, withdraws money quickly and without problems directly to the card, a very creative and interesting application, I withdraw quite a lot of money from it just lying on the couch, I recommend everyone to download and try, Grab your luck. good game',
    peopleFound: 23,
  },
  {
    icon: img18,
    name: 'ब्लैक.बुगाटी',
    countStarts: 5,
    date: 'January 12, 2024',
    comment:
      "Good evening, I want to introduce you to the Aviator application, in my opinion it is the best application, which can be good customizable, addictive, interesting, simple, detailed, attractive, but it is better for you to try it yourself, let's go in Go and see for yourself, you will like it, you will get a lot of unforgettable and positive emotions.",
    peopleFound: 19,
  },
  {
    icon: img19,
    name: 'ओपोसम निंजा',
    countStarts: 5,
    date: 'January 11, 2024',
    comment:
      'मAviator works great and withdraws money quickly. I advise everyone to download this application',
    peopleFound: 43,
  },
  {
    icon: img20,
    name: 'ट्रेज़र',
    countStarts: 5,
    date: 'January 3, 2024',
    comment:
      "Aviator app is top level, I really liked Aviator, it's really great, the rates in Aviator are as good as possible, I recommend Aviator to everyone because it's really great, I like Aviator really Come on, really download Aviator, you will not regret here, you earn very quickly, you will not regret it, the money you earn will not be unnecessary, I recommend everyone to download Aviator",
    peopleFound: 26,
  },
  {
    icon: img21,
    name: 'चेरी पाई',
    countStarts: 5,
    date: 'January 3, 2024',
    comment:
      'Amazing game, I really liked it, a lot of advantages, a lot of positive emotions, a lot of additional functions, if there are some shortcomings that need to be worked on, but overall I am satisfied with the application and the fact that Aviator did a great job Aviator it suited me perfectly, I really liked everyone and the mood was good',
    peopleFound: 23,
  },
  {
    icon: img22,
    name: 'मैथ्यू',
    countStarts: 5,
    date: 'January 3, 2024',
    comment:
      'A good application, there are a lot of good games in which you can play and win, Aviator is an excellent casino, I myself played and won a lot and I advise you to come and play, try it, believe me, You can win normally and withdraw quickly.',
    peopleFound: 19,
  },
  {
    icon: img23,
    name: 'अप्रमाणित सत्य',
    countStarts: 5,
    date: 'December 27, 2023',
    comment:
      'I played in many casinos and bookmakers. Aviator is tops in every aspect. But the main criterion is withdrawal of funds within one minute. I recommend it. Great line, great possibilities, beautiful design, amazing slots, convenient application menu. I would like to pay special attention to technical support! Payments without commission within 5-20 minutes depending on the withdrawal amount, lots of tools for depositing funds',
    peopleFound: 67,
  },
]
